/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Container to Restricted responsável por construir o routeamento do container e sincroniza o componente em questão.
 *
 * @version 20191120
 * @since 20191120 Initial release
 *
 */

import { lazy, Suspense, useContext } from 'react';
import FooterContainer from './shared/Footer';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';
import Loading from 'components/core/Loading';
import lazyLoader from 'services/lazyLoader';

// Components
import Processes from 'routes/Processes';
import Facilities from 'routes/Facilities';
import Solutions from 'routes/Solutions';
import CreateClient from 'routes/client/CreateClient';
import EditClient from 'routes/client/EditClient';
import Modules from 'routes/Modules';
import Product from 'routes/Product';
import BusinessModels from 'routes/BusinessModels';
import ReformulationBusinessModels from 'routes/ReformulationBM';
import Proposal from 'routes/Proposal';
import CookiePolicy from 'routes/CookiePolicy';
import EditUser from 'routes/User/Edit/EditUser';
import Backoffice from 'routes/Backoffice';
import Sidebar from 'components/common/Sidebar';
import RedefinePasswordDialog from 'components/core/dialogs/RedefinePasswordDialog';
import FacilityPro from 'routes/FacilityPro';
import { UserContext } from 'contexts/userContext';
import useUser from 'hooks/user/useUser';
import ErrorClock from 'components/errors/ErrorClock';
import { useUserStore } from 'store/user';
import Logout from 'pages/auth/Logout';
import useShadowProject from 'hooks/projects/useShadowProject';

const Error404 = lazy(() => lazyLoader(() => import('components/errors/Error404')));
const Error403 = lazy(() => lazyLoader(() => import('components/errors/Error403')));

/**
 * switchRoute
 *
 * @param {*} url
 * @param {*} mudou_password
 */
const switchRoute = (url, userChangedPassword, userID) => {
    if (userChangedPassword) {
        return (
            <Switch>
                {/* Fallback to Solutions */}
                <Route exact path={`${url}/home`}>
                    <Redirect to={`${url}/solutions`} />
                </Route>
                <Route exact path={`${url}/efficiency-measures`}>
                    <Redirect to={`${url}/solutions`} />
                </Route>

                <Route exact path={`${url}/processes`} component={Processes} />
                <Route exact path={`${url}/processes/:id/reformulation/business-models`} component={ReformulationBusinessModels} />
                <Route exact path={`${url}/processes/:id/reformulation/proposal`} component={Proposal} />
                <Route exact path={`${url}/processes/:id/reformulation`} component={Processes} />
                <Route exact path={`${url}/facilities`} component={Facilities} />
                <Route exact path={`${url}/facility`} component={FacilityPro} />
                <Route exact path={`${url}/client`} component={EditClient} />
                <Route exact path={`${url}/client/create`} component={CreateClient} />
                <Route exact path={`${url}/solutions`} component={Solutions} />
                <Route exact path={`${url}/solutions/:id/sub-products`} component={Modules} />
                <Route exact path={`${url}/solutions/:id/sub-products/:subProduct/modules`} component={Modules} />
                <Route exact path={`${url}/solutions/:id/sub-products/:subProduct`} component={Product} />
                <Route exact path={`${url}/solutions/:id/sub-products/:subProduct/modules/:module`} component={Product} />
                <Route exact path={`${url}/solutions/:id/modules`} component={Modules} />
                <Route exact path={`${url}/solutions/:id/modules/:module`} component={Modules} />
                <Route exact path={`${url}/solutions/:id/business-models`} component={BusinessModels} />
                <Route exact path={`${url}/solutions/:id/proposal`} component={Proposal} />
                <Route exact path={`${url}/error`} component={ErrorClock} />
                <Route exact path={`${url}/logout`} component={Logout} />
                {/* NEW PRODUCTS 2022 */}
                {/* only QC #1814 */}
                {<Route exact path={`${url}/solutions/:id/`} component={Modules} />}
                {<Route exact path={`${url}/solutions/:id/:module`} component={Modules} />}
                <Route exact path={`${url}/user`} component={EditUser} />

                {/* COOKIES */}
                <Route exact path={`${url}/cookie-policies`} component={CookiePolicy} />
                {/* BACKOFFICE LAST PLEASE*/}
                <Route exact path={`${url}/backoffice`} component={Backoffice} />
                <Route exact path={`${url}/backoffice/:product`} component={Backoffice} />
                <Route exact path={`${url}/backoffice/:product/:section`} component={Backoffice} />
                <Route exact path={`${url}/403`} component={Error403} />
                <Route component={Error404} />
            </Switch>
        );
    } else {
        return <RedefinePasswordDialog userID={userID} />;
    }
};

/**
 *
 * @param {*} param0
 */
const Restricted = ({ match, userChangedPassword }) => {
    const { isB2C, companyProfileId } = useContext(UserContext);
    const { user } = useUserStore();
    const userID = user?.id;
    useUser();
    useShadowProject();

    // #region Cancel requests on page leave
    // const prevLocation = useRef(useLocation().pathname);

    // history.listen((location) => {
    //     if(prevLocation.current !== location.pathname) {
    //         axios.defaults.cancelCustom.cancel();
    //         prevLocation.current = location.pathname;
    //     }
    // });

    // #endregion
    const _userChangedPassword = userChangedPassword || user?.is_logged_by_sso;
    return (
        <main className="app-main-content-wrapper">
            {userID ?
                <>
                    <div className="app-main-content">
                        <Suspense fallback={<Loading useTopLoading={true} size={30} />}>
                            <Sidebar />
                            {switchRoute(match.url, _userChangedPassword, userID, { companyProfileId, isB2C })}
                        </Suspense>
                    </div>
                    <FooterContainer isUserAuthenticated={true} />
                </>
            :   <Loading />}
        </main>
    );
};

Restricted.propTypes = {
    match: PropTypes.object.isRequired,
    userChangedPassword: PropTypes.bool.isRequired,
};
export default Restricted;
