import { deleteProjectHandler, saveProjectHandler } from 'api/projects';
import { ReduxContext } from 'contexts/redux/reduxContext';
import { StatusCodes } from 'http-status-codes';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { containString } from 'services/util/auxiliaryUtils';
import { useProjects } from 'store/solutions/projects';

let deleteProjectAbortController: AbortController;
let editProjectAbortController: AbortController;
const useShadowProject = () => {
    const history = useHistory();
    const {
        shadowProject,
        actions: { setShadowProject },
    } = useProjects();
    const { proposal } = useContext(ReduxContext);

    const deleteProductProject = async () => {
        try {
            if (!shadowProject) return;
            deleteProjectAbortController && deleteProjectAbortController.abort();
            deleteProjectAbortController = new AbortController();
            const rspDeleteProject = await deleteProjectHandler(shadowProject, deleteProjectAbortController);
            if (rspDeleteProject?.status === StatusCodes.OK) {
                setShadowProject(null);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const putProductProject = async () => {
        try {
            if (!shadowProject) return;
            editProjectAbortController && editProjectAbortController.abort();
            editProjectAbortController = new AbortController();
            const projectDetails = {
                description: `Proposal_${proposal?.id}`,
                simulation_id: proposal?.id,
            };
            const response = await saveProjectHandler(projectDetails, shadowProject, editProjectAbortController);
            if (response?.status === StatusCodes.OK) {
                setShadowProject(null);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!containString(['proposal', 'business-models'], location.pathname) && !!shadowProject) {
            deleteProductProject();
        }
    }, [history.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (proposal?.id) {
            putProductProject();
        }
    }, [proposal?.id]); // eslint-disable-line react-hooks/exhaustive-deps
};
export default useShadowProject;
